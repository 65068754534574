import React, { useState, useEffect } from 'react'

import { get } from 'src/http'

import { Base64Image, LongText } from 'src/components'

import { Text, useToast, VStack } from '@chakra-ui/react'

export default function PostItem({ postId, ...attr }) {
  const [ post, setPost ] = useState(null)

  const toast = useToast()

  const getPost = async () => {
    try {
      const resp = await get(`/post/one/${postId}`)
      if (resp.status >= 400) throw new Error(resp.data)
      setPost(resp.data)
    }
    catch (err) {
      toast({
        title: err.message,
        status: "error"
      })
    }
  }

  useEffect(() => {
    (async () => {
      await getPost()
    })()

    return () => {
      setPost(null)
    }
  }, [])

  if (post == null) return <></>
  return (
    <>
      <VStack w={{ base: '100%', md: '80%', lg: '65%', xl: '45%' }} rounded='md' shadow='md' {...attr}>
        {
          post.photo != null && 
            <Base64Image roundedTop='md' media={post.photo} />
        }
        <VStack w='100%' pb='2' pt='0' px='4'>
          <Text w='100%' align='start'>{post.body}</Text>
        </VStack>
      </VStack>
    </>
  )
}
