import React, { useState, useEffect } from 'react'

import { churchData } from 'src/assets/data'
import { Navbar, Carousel, Footer, Map } from 'src/components'

import { HStack, Text, Image, VStack } from '@chakra-ui/react'

export default function HomePage() {
  const [ screenWidth, setScreenWidth ] = useState(window.innerWidth)
  const [ isBigScreen, setIsBigScreen ] = useState(window.innerWidth > 1000)

  useEffect(() => {
    document.title = 'Home | GBI Kalvari'

    const onResize = () => {
      setScreenWidth(window.innerWidth)
      setIsBigScreen(window.innerWidth > 1000)
    }
    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  const photos = [
    require('src/assets/images/jadwal-desktop.jpg')
  ]
  
  const photosSmall = [
    require('src/assets/images/jadwal-mobile.jpg')
  ]

  return (
    <>
      <Navbar page='Home' />
      <VStack pt='104'></VStack>
      <Carousel photos={photos} photosSmall={photosSmall} />
      <VStack pb='10' bgColor='gray.100'>
        <VStack pt='8' px={!isBigScreen && '10'} w={isBigScreen && '45%'} spacing='4'>
          <VStack>
            <Text fontSize='3xl' fontWeight='semibold'>
              Tentang Kami
            </Text>
            <Text align='center'>{churchData.tentangKami}</Text>
          </VStack>
          <VStack>
            <Text fontSize='3xl' fontWeight='semibold'>
              Visi
            </Text>
            <Text align='center'>{churchData.visi}</Text>
          </VStack>
          <VStack>
            <Text fontSize='3xl' fontWeight='semibold'>
              Misi
            </Text>
            <Text align='center'>{churchData.misi}</Text>
          </VStack>
          <VStack>
            <Text fontSize='3xl' fontWeight='semibold'>
              Goals
            </Text>
            {
              churchData.goals.map((goal, idx) => 
                <Text key={idx} align='center'>{goal}</Text>
              )
            }
          </VStack>
        </VStack>
      </VStack>
      <VStack px={isBigScreen ? '40' : '10'} pt='8' pb='10'>
        <Text fontSize='3xl' fontWeight='semibold'>
          Profil Pendiri Gereja
        </Text>
        {
          isBigScreen ? (
            <HStack spacing='10'>
              <Image h='300' src={require("src/assets/images/gembala.jpeg")} alt='Foto Gembala' />
              <VStack>
                {
                  churchData.profilPendiriGereja.map((par, idx) => 
                    <Text key={idx}>{par}</Text>
                  )
                }
              </VStack>
            </HStack>
          ) : (
            <VStack spacing='4'>
              <Image h='300' src={require("src/assets/images/gembala.jpeg")} alt='Foto Gembala' />
              {
                churchData.profilPendiriGereja.map((par, idx) => 
                  <Text key={idx} align='center'>{par}</Text>
                )
              }
            </VStack>
          )
        }
      </VStack>
      <Map />
      <Footer />
    </>
  )
}
