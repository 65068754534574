import React, { useState, useEffect } from 'react'

import theme from 'src/theme'
import { post } from 'src/http'
import { useAppContext } from 'src/App'

import { MenuDrawer } from 'src/components'

import { useDisclosure, HStack, Link, VStack, Text, Image, IconButton, Icon, useToast } from '@chakra-ui/react'
import { AiOutlineMenu } from 'react-icons/ai'

export default function Navbar({ page }) {
  const { isLoggedIn, setIsLoggedIn } = useAppContext()

  const [ screenWidth, setScreenWidth ] = useState(window.innerWidth)
  const [ isBigScreen, setIsBigScreen ] = useState(window.innerWidth > 1000)

  useEffect(() => {
    const onResize = () => {
      setScreenWidth(window.innerWidth)
      setIsBigScreen(window.innerWidth > 1000)
    }
    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  const { 
    isOpen: isMenuOpen, 
    onOpen: onMenuOpen, 
    onClose: onMenuClose 
  } = useDisclosure()

  const [ isLoading, setIsLoading ] = useState(false)
  
  const toast = useToast()

  const onLogout = async () => {
    try {
      setIsLoading(true)
      const refreshToken = localStorage.getItem('refreshToken')
      const resp = await post('/auth/logout', {
        token: refreshToken
      })
      if (resp.status >= 400) throw new Error(resp.data)
      setIsLoggedIn(false)
      localStorage.removeItem('token')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('user')
      setIsLoading(false)
    }
    catch (err) {
      setIsLoading(false)
      toast({
        title: err.message,
        status: "error"
      })
    }
  }

  return (
    <>
      <VStack zIndex={1000} w='100%' pt='1' bgColor='gray.900' as='header' position='fixed'>
        <HStack w='100%' py='3' px={{ base: 4, lg: '16' }} alignItems='center' justifyContent='space-between'>
          <HStack alignItems='center' spacing='4'>
            <Image h='10' src={require('src/assets/images/gbi-logo.png')} />
            <Link href='/'>
              <Text fontWeight='bold' color='white' _hover={{ as: 'u', color: 'gray.200' }}>GBI Kalvari</Text>
            </Link>
          </HStack>
          {
            isBigScreen ? (
              <HStack spacing='8'>
                <Link href='/'>
                  <Text as={(page === 'Home') && 'u'} fontSize='sm' fontWeight='bold' color='white' _hover={{ as: 'u', color: 'gray.200' }}>
                    HOME
                  </Text>
                </Link>
                <Link href='/stream'>
                  <Text as={(page === 'Stream') && 'u'} fontSize='sm' fontWeight='bold' color='white' _hover={{ as: 'u', color: 'gray.200' }}>
                    STREAM
                  </Text>
                </Link>
                <Link href='/posts'>
                  <Text as={(page === 'Posts') && 'u'} fontSize='sm' fontWeight='bold' color='white' _hover={{ as: 'u', color: 'gray.200' }}>
                    POSTS
                  </Text>
                </Link>
                <Link href='/prayer'>
                  <Text as={(page === 'Prayer') && 'u'} fontSize='sm' fontWeight='bold' color='white' _hover={{ as: 'u', color: 'gray.200' }}>
                    PRAYER
                  </Text>
                </Link>
                {/* <Link href='/offering'>
                  <Text as={(page === 'Offering') && 'u'} fontSize='sm' fontWeight='bold' color='white'>OFFERING</Text>
                </Link>
                <Link href='/groups'>
                  <Text as={(page === 'Groups') && 'u'} fontSize='sm' fontWeight='bold' color='white'>GROUPS</Text>
                </Link>
                <Link href='/profile'>
                  <Text as={(page === 'Profile') && 'u'} fontSize='sm' fontWeight='bold' color='white'>PROFILE</Text>
                </Link> */}
              </HStack>
            ) : (
              <>
                <IconButton variant='ghost' icon={<Icon color='white' as={AiOutlineMenu} />}
                  _hover={{ bgColor: 'gray.900' }} onClick={onMenuOpen}
                />
                <MenuDrawer page={page} isOpen={isMenuOpen} onClose={onMenuClose} />
              </>
            )
          }
        </HStack>
        <HStack w='100%' py='1' px={isBigScreen ? '16' : 4} justifyContent='space-between' bgColor={theme.primary[500]}>
          <VStack></VStack>
          {
            !isLoggedIn ? (
              <HStack spacing={isBigScreen ? '8' : '4'}>
                <Link href='/register'>
                  <Text fontSize='xs' fontWeight='bold' color='white'>REGISTER</Text>
                </Link>
                <Link href='/login/wa'>
                  <Text fontSize='xs' fontWeight='bold' color='white'>LOGIN</Text>
                </Link>
              </HStack>
            ) : (
              <Link onClick={onLogout}>
                <Text fontSize='xs' fontWeight='bold' color='white'>LOGOUT</Text>
              </Link>
            )
          }
        </HStack>
      </VStack>
    </>
  )
}
