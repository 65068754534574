import React, { useState, useContext } from 'react'

import { post } from 'src/http'
import { useAppContext } from 'src/App'

import { useToast, Link, Text, Drawer, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerFooter, DrawerOverlay, HStack, VStack, Divider } from '@chakra-ui/react'

export default function MenuDrawer({ page, isOpen, onClose }) {
  const { isLoggedIn, setIsLoggedIn } = useAppContext()

  const [ isLoading, setIsLoading ] = useState(false)
  
  const toast = useToast()

  const onLogout = async () => {
    try {
      setIsLoading(true)
      const refreshToken = localStorage.getItem('refreshToken')
      const resp = await post('/auth/logout', {
        token: refreshToken
      })
      if (resp.status >= 400) throw new Error(resp.data)
      setIsLoggedIn(false)
      localStorage.removeItem('token')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('user')
      setIsLoading(false)
    }
    catch (err) {
      setIsLoading(false)
      toast({
        title: err.message,
        status: "error"
      })
    }
  }
  
  return (
    <>
      <Drawer isOpen={isOpen} onClose={onClose} placement='right'>
        <DrawerOverlay />
        <DrawerContent bgColor='gray.900'>
          <DrawerCloseButton />
          <DrawerHeader>
            <VStack pt='10' pl='4' alignItems='flex-start' spacing='4'>
              <Link href='/'>
                <Text as={(page === 'Home') && 'u'} fontSize='sm' fontWeight='bold' color='white'>HOME</Text>
              </Link>
              <Link href='/stream'>
                <Text as={(page === 'Stream') && 'u'} fontSize='sm' fontWeight='bold' color='white'>STREAM</Text>
              </Link>
              <Link href='/posts'>
                <Text as={(page === 'Posts') && 'u'} fontSize='sm' fontWeight='bold' color='white'>POSTS</Text>
              </Link>
              <Link href='/prayer'>
                <Text as={(page === 'Prayer') && 'u'} fontSize='sm' fontWeight='bold' color='white'>PRAYER</Text>
              </Link>
              <Divider />
              {
                !isLoggedIn ? (
                  <>
                    <Link href='/login/wa'>
                      <Text as={(page === 'Login') && 'u'} fontSize='sm' fontWeight='bold' color='white'>LOGIN</Text>
                    </Link>
                    <Link href='/register'>
                      <Text as={(page === 'Register') && 'u'} fontSize='sm' fontWeight='bold' color='white'>REGISTER</Text>
                    </Link>
                  </>
                ) : (
                  <Link onClick={onLogout}>
                    <Text fontSize='sm' fontWeight='bold' color='white'>LOGOUT</Text>
                  </Link>
                )
              }
              {/* <Link href='/offering'>
                <Text as={(page === 'Offering') && 'u'} fontSize='sm' fontWeight='bold' color='white'>OFFERING</Text>
              </Link>
              <Link href='/groups'>
                <Text as={(page === 'Groups') && 'u'} fontSize='sm' fontWeight='bold' color='white'>GROUPS</Text>
              </Link>
              <Link href='/profile'>
                <Text as={(page === 'Profile') && 'u'} fontSize='sm' fontWeight='bold' color='white'>PROFILE</Text>
              </Link> */}
            </VStack>
          </DrawerHeader>
          <DrawerFooter>

          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}
