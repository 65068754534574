import React, { useState } from 'react'
import theme from 'src/theme'
import { get } from 'src/http'
import { useAppContext } from 'src/App'
import { Navbar } from 'src/components'
import { Button, HStack, Link, Image, Input, Text, useToast, VStack, Stack } from '@chakra-ui/react'

export default function RequestForgetPasswordPage() {
  const { setIsLoggedIn } = useAppContext()

  const [ phone, setPhone] = useState('') 
  const [ isLoading, setIsLoading ] = useState(false)

  const onPhoneChange = (e) => { 
    setPhone(e.target.value)
  }

  const validateInput = () => {
    if (phone == null || phone.length === 0) throw new Error('Phone cannot be empty')
  }

  const toast = useToast()

  const onSubmit = async () => {
    try {
      setIsLoading(true)
      validateInput()
      const resp = await get(`/auth/forget/+62${phone}`)
      if (resp.status >= 400) throw new Error(resp.data)
      setIsLoading(false)
      window.location.href = '/login/wa'
      toast({
        title: resp.data,
        status: "success"
      })
    }
    catch (err) {
      setIsLoading(false)
      toast({
        title: err.message,
        status: "error"
      })
    }
  }

  return (
    <>
      <Navbar page='Login' />
      <Stack direction={{ base: 'column', lg: 'row' }} pt={{ base: '24', lg: '32' }} justifyContent={{ base: 'center' }} alignItems='center'>
        <Image w={{ base: '85%', lg: '40%' }} src={require('src/assets/images/forget.png')} />
        <VStack w={{ base: '85%', lg: '40%' }} px={{ base: '0', lg: '6' }} alignItems='start'>
          <Text fontWeight='extrabold' fontSize='5xl' color={theme.primary[500]}>Lupa Password</Text>
          <Text>Nomor Telepon</Text>
          <HStack w='100%'>
            <Text fontWeight='bold'>+62</Text>
            <Input w='100%' onChange={onPhoneChange} type='number' />
          </HStack>
          <VStack w='100%'>
            <Button onClick={onSubmit} w='100%' mt='4' bgColor={theme.primary[500]} color='white' isLoading={isLoading}>
              Submit
            </Button>
            <HStack spacing="1">
              <Text>
                Don't have an account? 
              </Text>
              <Link href="/register">
                <Text fontWeight="bold" color={theme.primary[500]}>
                  Register
                </Text>
              </Link>
            </HStack>
          </VStack>
        </VStack>
      </Stack>
      <VStack pt='10' />
    </>
  )
}
