import React from 'react'

import { PrayerItem } from 'src/components'

import { Accordion, AccordionButton, AccordionItem, Text, VStack, Box, AccordionPanel, AccordionIcon } from '@chakra-ui/react'

export default function PrayerList({ prayers, ...attr }) {
  if (prayers == null) return <></>
  return (
    <>
      <VStack w='100%' alignItems='start' {...attr}>
        <Text>{prayers.undone.length} permohonan doa</Text>
        {
          prayers.undone.map(prayer => 
            <PrayerItem key={prayer._id} prayer={prayer} />
          )
        }
        <VStack pt='2' />
        <Accordion w='100%' allowToggle>
          <AccordionItem>
            <AccordionButton rounded='md'>
              <Box as="span" flex='1' textAlign='left'>
                Terjawab
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb='4'>
              <Text>{prayers.done.length} permohonan doa</Text>
              {
                prayers.done.map(prayer => 
                  <PrayerItem key={prayer._id} prayer={prayer} />
                )
              }
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </VStack>
    </>
  )
}
