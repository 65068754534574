import React, { useState } from 'react'
import theme from 'src/theme'
import { post } from 'src/http'
import { Navbar } from 'src/components'
import { Image, Text, VStack, HStack, Button, Input, Stack, useToast, Link } from '@chakra-ui/react'

export default function RegisterPage1({ registerInfo, setRegisterInfo, setPage }) {
  const onUsernameChange = (e) => { 
    setRegisterInfo(prev => ({
      ...prev,
      username: e.target.value
    })) 
  }

  const onPasswordChange = (e) => { 
    setRegisterInfo(prev => ({
      ...prev,
      password: e.target.value
    })) 
  }
  
  const onConfirmPasswordChange = (e) => { 
    setRegisterInfo(prev => ({
      ...prev,
      confirmPassword: e.target.value
    })) 
  }

  const validateInput = () => {
    if (registerInfo.username == null || registerInfo.username.length === 0) throw new Error('Username cannot be empty')
    if (registerInfo.password == null || registerInfo.password.length === 0) throw new Error('Password cannot be empty')
    if (registerInfo.confirmPassword == null || registerInfo.confirmPassword.length === 0) throw new Error('Password confirmation cannot be empty')
    if (registerInfo.password != registerInfo.confirmPassword) throw new Error('Password tidak cocok')
  }

  const toast = useToast()

  const [ isLoading, setIsLoading ] = useState(false)
  const onRegister = async () => {
    try {
      setIsLoading(true)
      validateInput()
      const resp = await post('/auth/check', registerInfo)
      if (resp.status >= 400) throw new Error(resp.data)
      setIsLoading(false)
      setPage(2)
    }
    catch (err) {
      setIsLoading(false)
      toast({
        title: err.message,
        status: "error"
      })
    }
  }

  return (
    <>
      <Navbar page='Register' />
      <Stack direction={{ base: 'column', lg: 'row' }} pt={{ base: '24', lg: '32' }} justifyContent={{ base: 'center' }} alignItems='center'>
        <Image w={{ base: '85%', lg: '40%' }} src={require('src/assets/images/register.png')} />
        <VStack w={{ base: '85%', lg: '40%' }} px={{ base: '0', lg: '6' }} alignItems='start'>
          <Text fontSize='5xl' fontWeight='extrabold' color={theme.primary[500]}>Register</Text>
          <Text>Username</Text>
          <Input onChange={onUsernameChange} type='text' />
          <Text>Password</Text>
          <Input onChange={onPasswordChange} type='password' />
          <Text>Confirm Password</Text>
          <Input onChange={onConfirmPasswordChange} type='password' />
          <VStack w='100%'>
            <Button onClick={onRegister} w='100%' mt='4' bgColor={theme.primary[500]} color='white' isLoading={isLoading}>
              Register
            </Button>
            <HStack spacing="1">
              <Text>
                Already have an account? 
              </Text>
              <Link href="/login/wa">
                <Text fontWeight="bold" color={theme.primary[500]}>
                  Login
                </Text>
              </Link>
            </HStack>
          </VStack>
        </VStack>
      </Stack>
    </>
  )
}
