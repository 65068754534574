import React, { useState } from 'react'
import { Text, VStack, Input, HStack, Button, Select, useToast, Stack, Image, Link } from '@chakra-ui/react'
import { AddressInput, Navbar } from 'src/components'
import theme from 'src/theme'
import { post } from 'src/http'
import { postImage } from 'src/utils'

export default function RegisterPage2({ registerInfo, setRegisterInfo }) {
  const onNameChange = (e) => {
    setRegisterInfo((prev) => ({
      ...prev,
      name: e.target.value
    }))
  }

  const onPhoneChange = (e) => {
    setRegisterInfo((prev) => ({
      ...prev,
      phone: e.target.value
    }))
  }

  const setAddress = (value) => {
    setRegisterInfo((prev) => ({
      ...prev,
      address: value
    }))
  }

  const onGenderChange = (e) => {
    setRegisterInfo((prev) => ({
      ...prev,
      gender: e.target.value
    }))
  }

  const onBirthChange = (e) => {
    setRegisterInfo((prev) => ({
      ...prev,
      birth: e.target.value
    }))
  }

  const onPhotoChange = (e) => {
    setRegisterInfo((prev) => ({
      ...prev,
      photo: e.target.files[0]
    }))
  }

  const validateInput = () => {
    console.log(registerInfo)
    if (registerInfo.name == null || registerInfo.name.length === 0) throw new Error('Name cannot be empty')
    if (registerInfo.phone == null || registerInfo.phone.length === 0) throw new Error('Phone cannot be empty')
    if (registerInfo.address == null) throw new Error('Address cannot be empty')
    if (registerInfo.address.province == null || registerInfo.address.province.length === 0) throw new Error('Province cannot be empty')
    if (registerInfo.address.regency == null || registerInfo.address.regency.length === 0) throw new Error('City cannot be empty')
    if (registerInfo.address.district == null || registerInfo.address.district.length === 0) throw new Error('District cannot be empty')
    if (registerInfo.address.subdistrict == null || registerInfo.address.subdistrict.length === 0) throw new Error('Subdistrict cannot be empty')
    if (registerInfo.address.street == null || registerInfo.address.street.length === 0) throw new Error('Street cannot be empty')
    if (registerInfo.gender == null || registerInfo.gender.length === 0) throw new Error('Gender cannot be empty')
  }

  const toast = useToast()

  const [ isRegisterLoading, setIsRegisterLoading ] = useState(false)
  const onRegister = async () => {
    try {
      setIsRegisterLoading(true)
      validateInput()
      let media = null
      if (registerInfo.photo != null) {
        media = await postImage(registerInfo.photo)
        console.log(media)
        if (media == null) throw new Error('Gagal mengunggah foto')
      }
      const resp = await post('/auth/register', {
        ...registerInfo, 
        photo: (media != null ? media._id : null),
        phone: '+62' + registerInfo.phone,
      })
      if (resp.status >= 400) throw new Error(resp.data)
      setIsRegisterLoading(false)
      localStorage.setItem('user', JSON.stringify(resp.data.user))
      localStorage.setItem('token', resp.data.token)
      localStorage.setItem('refreshToken', resp.data.refreshToken)
      window.location.href= '/'
    }
    catch (err) {
      setIsRegisterLoading(false)
      toast({
        title: err.message,
        status: "error"
      })
    }
  }

  return (
    <>
      <Navbar page='Register' />
      <Stack direction={{ base: 'column', lg: 'row' }} pt={{ base: '24', lg: '32' }} justifyContent={{ base: 'center' }} alignItems='center'>
        <Image mt='24' w={{ base: '85%', lg: '40%' }} src={require('src/assets/images/register.png')} />
        <VStack w={{ base: '85%', lg: '40%' }} px={{ base: '0', lg: '6' }} alignItems='start'>
          <Text fontSize='5xl' fontWeight='extrabold' color={theme.primary[500]}>Register</Text>
          <VStack w='100%' alignItems='start'>
            <Text>Nama Lengkap</Text>
            <Input value={registerInfo.name} onChange={onNameChange} />
            <Text>Nomor Telepon</Text>
            <HStack w='100%'>
              <Text fontWeight='bold'>+62</Text>
              <Input w='100%' type='number' value={registerInfo.phone} onChange={onPhoneChange} />
            </HStack>
            <Text>Alamat</Text>
            <AddressInput value={registerInfo.address} onChange={setAddress} />
            <Text>Jenis Kelamin</Text>
            <Select value={registerInfo.gender} onChange={onGenderChange} placeholder='-'>
              <option value='Male'>Laki-laki</option>
              <option value='Female'>Perempuan</option>
            </Select>
            <Text>Tanggal Lahir</Text>
            <Input type='date' value={registerInfo.birth} onChange={onBirthChange} />
            <Text>Foto Profil</Text>
            <Input type='file' variant='ghost' p='0' borderRadius='0' onChange={onPhotoChange} />
            <VStack w='100%'>
              <Button w='100%' bgColor={theme.primary[500]} color='white' isLoading={isRegisterLoading} onClick={onRegister}>Register</Button>
              <HStack spacing="1">
                <Text>
                  Already have an account? 
                </Text>
                <Link href="/login/wa">
                  <Text fontWeight="bold" color={theme.primary[500]}>
                    Login
                  </Text>
                </Link>
              </HStack>
            </VStack>
          </VStack>
        </VStack>
        <VStack pt='10' />
      </Stack>
    </>
  )
}
