import React, { useState } from 'react'
import theme from 'src/theme'
import { post } from 'src/http'
import { useAppContext } from 'src/App'
import { Navbar } from 'src/components'
import { Button, HStack, Link, Image, Input, Text, useToast, VStack, Stack, Icon, Box } from '@chakra-ui/react'
import { FaWhatsapp } from 'react-icons/fa'

export default function LoginPage() {
  const { setIsLoggedIn } = useAppContext()

  const [ loginInfo, setLoginInfo ] = useState({
    username: '',
    password: '',
  })
  const [ isLoading, setIsLoading ] = useState(false)

  const onUsernameChange = (e) => { 
    setLoginInfo(prev => ({
      ...prev,
      username: e.target.value
    })) 
  }

  const onPasswordChange = (e) => { 
    setLoginInfo(prev => ({
      ...prev,
      password: e.target.value
    })) 
  }

  const validateInput = () => {
    if (loginInfo.username == null || loginInfo.username.length === 0) throw new Error('Username cannot be empty')
    if (loginInfo.password == null || loginInfo.password.length === 0) throw new Error('Password cannot be empty')
  }

  const toast = useToast()

  const onLogin = async () => {
    try {
      setIsLoading(true)
      validateInput()
      const resp = await post('/auth/login', loginInfo)
      if (resp.status >= 400) throw new Error(resp.data)
      setIsLoading(false)
      localStorage.setItem('user', JSON.stringify(resp.data.user))
      localStorage.setItem('token', resp.data.token)
      localStorage.setItem('refreshToken', resp.data.refreshToken)
      setIsLoggedIn(true)
      window.location.href = '/'
    }
    catch (err) {
      setIsLoading(false)
      toast({
        title: err.message,
        status: "error"
      })
    }
  }

  return (
    <>
      <Navbar page='Login' />
      <Stack direction={{ base: 'column', lg: 'row' }} pt={{ base: '24', lg: '32' }} justifyContent={{ base: 'center' }} alignItems='center'>
        <Image w={{ base: '85%', lg: '40%' }} src={require('src/assets/images/login.png')} />
        <VStack w={{ base: '85%', lg: '40%' }} px={{ base: '0', lg: '6' }} alignItems='start'>
          <Text fontWeight='extrabold' fontSize='5xl' color={theme.primary[500]}>Login</Text>
          <Text>Username</Text>
          <Input onChange={onUsernameChange} type='text' />
          <Text>Password</Text>
          <Input onChange={onPasswordChange} type='password' />
          <HStack w='100%' justifyContent='space-between'>
            <HStack />
            <Link onClick={() => window.location.href = '/forget-password'}>
              <Text fontWeight='bold' color={theme.primary[500]}>
                Forget password?
              </Text>
            </Link>
          </HStack>
          <VStack w='100%'>
            <Button onClick={() => window.location.href = '/login/wa'} w='100%' bgColor='white' color={theme.primary[500]} border='2px' borderColor={theme.primary[500]} 
              isLoading={isLoading} leftIcon={<Icon as={FaWhatsapp} />}>
              Login with WhatsApp
            </Button>
            <Button onClick={onLogin} w='100%' mt='4' bgColor={theme.primary[500]} color='white' isLoading={isLoading}>
              Login
            </Button>
            <HStack spacing="1">
              <Text>
                Don't have an account? 
              </Text>
              <Link href="/register">
                <Text fontWeight="bold" color={theme.primary[500]}>
                  Register
                </Text>
              </Link>
            </HStack>
          </VStack>
        </VStack>
      </Stack>
      <VStack pt='10' />
    </>
  )
}
