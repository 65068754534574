import React, { useState, useContext, createContext } from 'react'
import { useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { HomePage, StreamPage, PostPage, ChangeForgetPasswordPage, LoginPage, WaLoginPage, RegisterPage, PrayerPage, NewPrayerPage, RequestForgetPasswordPage } from 'src/pages'

const AppContext = createContext()

export function useAppContext() {
  return useContext(AppContext)
}

export default function App() {
  const [ isLoggedIn, setIsLoggedIn ] = useState(false)
  
  const checkLoggedIn = async () => {
    try {
      const myToken = localStorage.getItem('refreshToken')
      return (myToken != null || myToken)
    }
    catch (err) {
      return false
    }
  }

  const appContextValue = { 
    isLoggedIn, setIsLoggedIn, checkLoggedIn, 
  }

  useEffect(() => {
    (async () => {
      const check = await checkLoggedIn()
      setIsLoggedIn(check)
    })()
  }, [])

  return (
    <>
      <AppContext.Provider value={appContextValue}>
        <Router>
          <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path='/stream' element={<StreamPage />} />
            <Route path='/posts' element={<PostPage />} />
            <Route path='/posts/:page' element={<PostPage />} />
            <Route path='/prayer' element={<PrayerPage />} />
            <Route path='/new-prayer' element={<NewPrayerPage />} />
            <Route path='/login' element={<LoginPage />} />
            <Route path='/login/wa' element={<WaLoginPage />} />
            <Route path='/register' element={<RegisterPage />} />
            <Route path='/forget-password' element={<RequestForgetPasswordPage />} />
            <Route path='/forget-password/:id' element={<ChangeForgetPasswordPage />} />
            {/* <Route path='/offering' element={<HomePage />} />
            <Route path='/groups' element={<HomePage />} />
            <Route path='/profile' element={<HomePage />} />
            <Route path='/login' element={<HomePage />} />
            <Route path='/register' element={<HomePage />} /> */}
          </Routes>
        </Router>
      </AppContext.Provider>
    </>
  )
}
