import React, { useEffect, useState } from 'react'

import { Box, HStack, Icon, IconButton, VStack } from '@chakra-ui/react'
import { AiOutlineRight, AiOutlineLeft } from 'react-icons/ai'

export default function Carousel({ photos, photosSmall }) {
  const [ page, setPage ] = useState(0)

  const [ screenWidth, setScreenWidth ] = useState(window.innerWidth)
  const [ isBigScreen, setIsBigScreen ] = useState(window.innerWidth > 600)

  useEffect(() => {
    const interval = setInterval(() => {
      setPage(prev => (prev+1)%photos.length)
    }, 5000)
    
    const onResize = () => {
      setScreenWidth(window.innerWidth)
      setIsBigScreen(window.innerWidth > 600)
    }
    window.addEventListener('resize', onResize)

    return () => {
      clearInterval(interval)
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return (
    <>
      <HStack h={isBigScreen ? 4/10*screenWidth : screenWidth} p='6' alignItems='center' justifyContent='space-between'
        bgImg={isBigScreen ? photos[page] : photosSmall[page]} bgPos='center' bgSize='cover' bgRepeat='no-repeat'>
        <IconButton variant='ghost' rounded='full' icon={<Icon as={AiOutlineLeft} />}
          onClick={() => setPage(prev => (prev-1+photos.length)%photos.length)} 
        />
        <VStack h='100%' alignItems='center' justifyContent='space-between'>
          <Box></Box>
          <HStack spacing='2'>
            {
              photos.map((_, idx) => 
                <Box key={idx} h='1' w='8' rounded='md' bgColor={page === idx ? 'white' : 'gray.400'} 
                  onClick={() => setPage(idx)}>
                </Box>
              )
            }
          </HStack>
        </VStack>
        <IconButton variant='ghost' rounded='full' icon={<Icon as={AiOutlineRight} />} 
          onClick={() => setPage(prev => (prev+1)%photos.length)}
        />
      </HStack>
    </>
  )
}
