import React, { useState } from 'react'

import { RegisterPage1, RegisterPage2 } from 'src/pages'

export default function RegisterPage() {
  const [ page, setPage ] = useState(2)

  const [ registerInfo, setRegisterInfo ] = useState({
    username: '',
    password: '',
    confirmPassword: '',
    name: '',
    phone: '',
    address: {
      province: '',
      regency: '',
      district: '',
      subdistrict: '',
      street: ''
    },
    gender: '',
    birth: '',
    photo: null,
  })

  return (
    <>
     {
        (page === 1) ? 
          <RegisterPage1 registerInfo={registerInfo} setRegisterInfo={setRegisterInfo} setPage={setPage} /> : 
          <RegisterPage2 registerInfo={registerInfo} setRegisterInfo={setRegisterInfo} setPage={setPage} />
     }
    </>
  )
}
