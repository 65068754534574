import axios from 'axios'

import config from 'src/config'

const postImage = async (image) => {
  if (image == null) return null

  const form = new FormData()
  form.append('image', image)
  
  return await axios.create({
    baseURL: config.API_URL,
    headers: { 'Content-Type': 'multipart/form-data' },
    validateStatus: (stat) => true
  }).post('/media/image', form)
    .then(resp => resp.data)
    .catch(err => console.log(err))
}

export default postImage