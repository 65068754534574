export default {
  API_URL: 'https://api.gbikalvari.com',
  // API_URL: 'http://localhost:3001',
  RT_URL: 'https://api-realtime.gbikalvari.com',
  MAIN_GROUP_ID: '62b687b9c380dd6fb816a857',
  RBI_GROUP_ID: '63d7e51baa135e2bd628693f',
  PBI_GROUP_ID: '63d7e612aa135e2bd628697d',
  KABI_GROUP_ID: '63d8c7a2c98a6b17d5474821',
  MP_GROUP_ID: '63d7e6d7aa135e2bd6286995',
  SUPPORT_EMAIL: 'contact.kalvariku@gmail.com',
  BANK_ACC: '728888859200'
}