import React, { useState, useEffect } from 'react'

import { get } from 'src/http'

import { Footer, Navbar, StreamList } from 'src/components'

import { useToast, VStack } from '@chakra-ui/react'

export default function StreamPage() {
  const [ streams, setStreams ] = useState(null)

  const toast = useToast()

  const getStreams = async () => {
    try {
      setStreams(null)
      const resp = await get('/stream')
      if (resp.status >= 400) throw new Error(resp.data)
      setStreams(resp.data)
    }
    catch (err) {
      toast({
        title: err.message,
        status: "error"
      })
    }
  }

  useEffect(() => {
    (async () => {
      await getStreams()
    })()

    return () => {
      setStreams(null)
    }
  }, [])

  return (
    <>
      <Navbar page='Stream' />
      <VStack pt='104' w='100%' minH='86vh'>
        <StreamList w='100%' streams={streams} />
      </VStack>
      <Footer bgColor='gray.100' />
    </>
  )
}
