import React from "react"

import { VStack, Text, AspectRatio } from "@chakra-ui/react"

export default function StreamItem({ stream, ...attr }) {
  if (stream == null) return <></>
  return (
    <>
      <VStack w={{ base: '94%', md: '76%', lg: '63%', xl: '44%' }} rounded='md' shadow='md' overflow='hidden' {...attr}>
        <AspectRatio minW={{ base: '94vw', md: '76vw', lg: '63vw', xl: '44vw' }} ratio={16/9}>
          <iframe allowFullScreen src={`https://www.youtube.com/embed/${stream.id.videoId}`} />
        </AspectRatio>
        <VStack w='100%' pb='2' pt='0' px='4'>
          <Text w='100%' algin='start'>{stream.snippet.title}</Text>
        </VStack>
      </VStack>
    </>
  )
}
