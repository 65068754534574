import React, { useState } from 'react'
import { HStack, Input, Select } from '@chakra-ui/react'
import { citiesData, districtsData, provincesData, subdistrictsData } from 'src/assets/data'

export default function AddressInput({ value, onChange }) {
  const [ address, setAddress ] = useState(value)

  const onProvinceChange = (e) => {
    setAddress((prev) => ({
      ...prev,
      province: e.target.value
    }))
    onChange(address)
  }

  const onRegencyChange = (e) => {
    setAddress((prev) => ({
      ...prev,
      regency: e.target.value
    }))
    onChange(address)
  }

  const onDistrictChange = (e) => {
    setAddress((prev) => ({
      ...prev,
      district: e.target.value
    }))
    onChange(address)
  }

  const onSubdistrictChange = (e) => {
    setAddress((prev) => ({
      ...prev,
      subdistrict: e.target.value
    }))
    onChange(address)
  }

  const onStreetChange = (e) => {
    setAddress((prev) => ({
      ...prev,
      street: e.target.value
    }))
    onChange(address)
  }

  return (
    <>
      <HStack w='100%'>
        <Select w='49%' onChange={onProvinceChange} placeholder='Provinsi'>
          {
            provincesData.map((province) => <option value={province}>{province}</option>)
          }
        </Select>
        <Select w='49%' onChange={onRegencyChange} placeholder='Kota/Kabupaten'>
          {
            citiesData[address.province] && 
              citiesData[address.province].map((city) => <option value={city}>{city}</option>)
          }
        </Select>
      </HStack>
      <HStack w='100%'>
        <Select w='49%' onChange={onDistrictChange} placeholder='Kecamatan'>
          {
            districtsData[address.regency] &&
              districtsData[address.regency].map((district) => <option value={district}>{district}</option>)
          }
        </Select>
        <Select w='49%' onChange={onSubdistrictChange} placeholder='Kelurahan'>
          {
            subdistrictsData[address.district] &&
              subdistrictsData[address.district].map((subdistrict) => <option value={subdistrict}>{subdistrict}</option>)
          }
        </Select>
      </HStack>
      <Input value={address.street} onChange={onStreetChange} placeholder='Jalan' />
    </>
  )
}
