import React from 'react'

import { PostItem } from 'src/components'

import { Spinner, VStack } from '@chakra-ui/react'

export default function PostList({ posts, ...attr }) {
  if (posts == null) return (
    <>
      <VStack {...attr}>
        <Spinner />
      </VStack>
    </>
  )
  return (
    <>
      <VStack {...attr}>
        {
          posts.map(postId => 
            <PostItem key={postId} mb='2' postId={postId} />
          )
        }
      </VStack>
    </>
  )
}
