import React from 'react'

import theme from 'src/theme'
import { put } from 'src/http'

import { Button, HStack, Text, useToast, VStack } from '@chakra-ui/react'
import { useState } from 'react'

export default function PrayerItem({ prayer }) {
  const toast = useToast()

  const [ isLoading, setIsLoading ] = useState(false)

  const onDone = async () => {
    try {
      setIsLoading(true)
      const resp = await put(`/prayer/${prayer._id}`, { status: 'DONE' })
      if (resp.status >= 400) throw new Error(resp.data)
      setIsLoading(false)
      toast({
        title: 'Puji Tuhan, permohonan doa terjawab',
        status: 'success',
      })
    }
    catch (err) {
      setIsLoading(false)
      toast({
        title: err.message,
        status: 'error',
      })
    }
  }

  return (
    <>
      <HStack w='100%' py='2' px='4' justifyContent='space-between' rounded='md' shadow='md'>
        <VStack alignItems='start'>
          <Text fontWeight='semibold' fontSize='xl'>{prayer.title}</Text>
          <Text>{prayer.body}</Text>
        </VStack>
        {
          (prayer.status === 'UNDONE') && 
            <Button onClick={onDone} bgColor={theme.primary[500]} color='white' isLoading={isLoading}>Selesai</Button>
        }
      </HStack>
    </>
  )
}
