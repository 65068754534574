const data = {
  tentangKami: 'GBI Kalvari adalah sebuah gereja di Cirebon dengan Gembala Jemaat Pdt. Anna S. Mailool yang didirikan oleh Pdt. Sutio Daud Mailool atau Theo Mailool dan dinaungi oleh Gereja Bethel Indonesia.',
  visi: 'Masuk dalam kerajaan yang tidak tergoncangkan (Ibrani 12:28)',
  misi: 'Membentuk orang percaya yang kuat dan yang bertahan sampai akhir (Matius 24:13)',
  goals: [
    '1. Mencari jiwa sebanyak-banyaknya sesuai dengan amanat agung Tuhan Yesus Kristus (Matius 28:19-20)',
    '2. Membentuk jemaat yang sehat dan kuat (Matius 16:18)'
  ],
  profilPendiriGereja: [
    'Pdt. Sutio Daud Mailool atau Theo Mailool dilahirkan di Cirebon pada tanggal 1 Agustus 1930. Panggilannya untuk melayani Tuhan telah dimulai sejak beliau berumur 16 tahun.',
    'Untuk sorang pemuda belia berusia 16 yang sejak kecil ditinggal ayahnya, keputusan untuk meninggalkan kehidupan duniawi adalah tekad yang luar biasa. Di tengah perjuangannya seorang diri demi ibunya dalam kehidupan yang keras, Tuhan telah memberinya cahaya menuju ladang pengabdian.',
    'Perjuangan dimulai pada 28 Februari 1953, dengan visi Tuhan pertama kali berkumpulah jemaat yang menjadi awal mula GBI Kalvari. Jemaat yang awalnya hanya terdiri dari 5 keluarga sekarang telah berkembang pesat menjadi lebih dari 1300 jiwa.'
  ]
}

export default data