import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import config from 'src/config'
import { get } from 'src/http'

import { Footer, Navbar, PostList } from 'src/components'

import { Tab, TabList, TabPanel, TabPanels, Tabs, useToast, VStack, Text } from '@chakra-ui/react'

export default function PostPage() {
  const { page } = useParams()

  const getSection = (page) => (
    page === 'rbi' ? 1 :
    page === 'pbi' ? 2 : 
    page === 'kabi' ? 3 :
    page === 'mp' ? 4 : 
    0
  )

  const getPage = (section) => `/posts${
    section === 1 ? '/rbi' : 
    section === 2 ? '/pbi' : 
    section === 3 ? '/kabi' : 
    section === 4 ? '/mp' : 
    ''
  }`

  const [ section, setSection ] = useState(getSection(page))

  const onTabChange = (idx) => {
    window.location.href = getPage(idx)
  }

  const [ posts, setPosts ] = useState([])

  const toast = useToast({
    position: "top",
    variant: "solid",
    isClosable: true
  })

  const getPosts = async () => {
    try {
      setPosts(null)
      const resp = (
        section === 0 ? await get('/post/all') :
        section === 1 ? await get(`/post/public/${config.RBI_GROUP_ID}`) :
        section === 2 ? await get(`/post/public/${config.PBI_GROUP_ID}`) :
        section === 3 ? await get(`/post/public/${config.KABI_GROUP_ID}`) :
        await get(`/post/public/${config.MP_GROUP_ID}`)
      )
      if (resp.status >= 400) throw new Error(resp.data)
      if (section === 0) setPosts(resp.data.pinned)
      else setPosts(resp.data.posts)
    }
    catch (err) {
      toast({
        title: err.message,
        status: "error"
      })
    }
  }

  useEffect(() => {
    (async () => {
      await getPosts()
    })()
  }, [ section ])

  return (
    <>
      <Navbar page='Posts' />
      <VStack pt='110' minH='86vh'>
        <Tabs variant='soft-rounded' w='100%' index={section} onChange={onTabChange} colorScheme='purple'>
          <TabList w='100%' h='100%' px={{ base: 4, lg: '16' }} overflow='scroll'>
            <Tab>Umum</Tab>
            <Tab>Remaja</Tab>
            <Tab>Pemuda</Tab>
            <Tab>Anak</Tab>
            <Tab>
              <Text noOfLines={1}>Musik & Pujian</Text>
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel><PostList posts={posts} /></TabPanel>
            <TabPanel><PostList posts={posts} /></TabPanel>
            <TabPanel><PostList posts={posts} /></TabPanel>
            <TabPanel><PostList posts={posts} /></TabPanel>
            <TabPanel><PostList posts={posts} /></TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
      <Footer bgColor='gray.100' />
    </>
  )
}
