import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import theme from 'src/theme'
import { put } from 'src/http'

import { Navbar } from 'src/components'

import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'

import { Icon, IconButton, InputGroup, InputRightElement, useToast } from '@chakra-ui/react'
import { Button, Center, Input, Text, VStack } from '@chakra-ui/react'

export default function ChangeForgetPasswordPage() {
  const { id } = useParams()

  const [ newPass, setNewPass ] = useState('')
  const [ confirmPass, setConfirmPass ] = useState('')
  const [ showNewPass, setShowNewPass ] = useState(false)
  const [ showConfirmPass, setShowConfirmPass ] = useState(false)

  const toast = useToast()

  const validateInput = () => {
    if (newPass == null || newPass.length < 6) throw new Error('Password baru minimal 6 karakter')
    if (confirmPass == null || confirmPass.length < 6) throw new Error('Konfirmasi password anda kosong')
    if (newPass !== confirmPass) throw new Error('Password baru anda tidak sesuai dengan konfirmasi. Periksa kembali password baru anda.')
  }

  const [ isLoading, setIsLoading ] = useState(false)

  const changePass = async () => {
    try {
      setIsLoading(true)
      validateInput()
      const resp = await put(`/auth/forget/change-password/${id}`, {
        newPassword: newPass
      })
      if (resp.status >= 400) throw new Error(resp.data)
      setIsLoading(false)
      toast({
        title: 'Sukses',
        description: 'Password anda berhasil diubah.',
        status: 'success',
        isClosable: true
      })
    }
    catch (err) {
      setIsLoading(false)
      toast({
        title: 'Error',
        description: err.message,
        status: 'error',
        isClosable: true
      })
    }
  }

  const [ screenWidth, setScreenWidth ] = useState(window.innerWidth)
  const [ isBigScreen, setIsBigScreen ] = useState(window.innerWidth > 1000)

  useEffect(() => {
    const onResize = () => {
      setScreenWidth(window.innerWidth)
      setIsBigScreen(window.innerWidth > 1000)
    }
    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])
  
  return (
    <>
      <Navbar />
      <VStack pt='63'></VStack>
      <Center mt='10'>
        <VStack w={!isBigScreen ? '80%' : '40%'} spacing='4'>
          <Text fontWeight='bold'>Masukkan password baru untuk akun anda</Text>
          <VStack pt='6' w='100%' spacing='4' alignItems='flex-start'>
            <VStack w='100%' alignItems='flex-start'>
              <Text>Password Baru</Text>
              <InputGroup>
                <Input type={showNewPass ? 'text' : 'password'} value={newPass} onChange={e => setNewPass(e.target.value)} />
                <InputRightElement>
                  <IconButton icon={<Icon as={showNewPass ? AiFillEyeInvisible : AiFillEye} />} variant='ghost' onClick={() => setShowNewPass(prev => !prev)} />
                </InputRightElement>
              </InputGroup>
            </VStack>
            <VStack w='100%' alignItems='flex-start'>
              <Text>Konfirmasi Password Baru</Text>
              <InputGroup>
                <Input type={showConfirmPass ? 'text' : 'password'} value={confirmPass} onChange={e => setConfirmPass(e.target.value)} />
                <InputRightElement>
                  <IconButton icon={<Icon as={showConfirmPass ? AiFillEyeInvisible : AiFillEye} />} variant='ghost' onClick={() => setShowConfirmPass(prev => !prev)} />
                </InputRightElement>
              </InputGroup>
            </VStack>
            <VStack w='100%' pt='4'>
              <Button w='100%' bgColor={theme.primary[500]} onClick={changePass} isLoading={isLoading}>
                <Text color='white'>Selesai</Text>
              </Button>
            </VStack>
          </VStack>
        </VStack>
      </Center>
    </>
  )
}
