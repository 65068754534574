import React, { useState } from 'react'

import theme from 'src/theme'

import { Navbar } from 'src/components'

import { VStack, Button, Text, Input, Box, Textarea, useToast, Link } from '@chakra-ui/react'
import { post } from 'src/http'

export default function NewPrayerPage() {
  const [ prayerData, setPrayerData ] = useState({
    title: '',
    body: '',
  })

  const onTitleChange = (e) => {
    setPrayerData(prev => ({
      ...prev,
      title: e.target.value
    }))
  }
  const onBodyChange = (e) => {
    setPrayerData(prev => ({
      ...prev,
      body: e.target.value
    }))
  }

  const [ isLoading, setIsLoading ] = useState(false)

  const toast = useToast()

  const onSubmit = async () => {
    try {
      setIsLoading(true)
      const resp = await post('/prayer', prayerData)
      if (resp.status >= 400) throw new Error(resp.data)
      toast({
        title: 'Permohonan doa berhasil tersimpan',
        status: 'success',
      })
      setIsLoading(false)
    }
    catch (err) {
      setIsLoading(false)
      toast({
        title: err.message,
        status: 'error',
      })
    }
  }

  return (
    <>
      <Navbar />
      <VStack px='6' pt='120' alignItems='start'>
        <Text fontWeight='bold' fontSize='2xl'>Permohonan Doa Baru</Text>
        <VStack h='4' />
        <Text>Judul</Text>
        <Input onChange={onTitleChange} />
        <Text>Detail</Text>
        <Textarea onChange={onBodyChange} />
        <Text>
          Sebelum membuat permohonan doa, silakan daftarkan diri anda terlebih dahulu di{' '}
          <Link href='/register' color={theme.primary[500]} fontWeight='bold'>sini</Link>
        </Text>
      </VStack>
      <VStack w='100%' position='fixed' bottom='0' px='6' py='4'>
        <Button onClick={onSubmit} w='100%' bgColor={theme.primary[500]} color='white' isLoading={isLoading}>Kirim</Button>
      </VStack>
    </>
  )
}
