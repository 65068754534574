import React, { useEffect, useState } from 'react'

import theme from 'src/theme'
import { get } from 'src/http'

import { Navbar, PrayerList } from 'src/components'

import { Button, Text, useToast, VStack } from '@chakra-ui/react'

export default function PrayerPage() {
  const [ prayers, setPrayers ] = useState()

  const toast = useToast()

  const getPrayers = async () => {
    try {
      const resp = await get('/prayer/mine')
      if (resp.status >= 400) throw new Error(resp.data)
      setPrayers(resp.data)
    }
    catch (err) {
      toast({
        title: err.message,
        status: 'error',
      })
    }
  }

  useEffect(() => {
    (async () => {
      await getPrayers()
    })()

    return () => {
      setPrayers(null)
    }
  }, [])

  return (
    <>
      <Navbar page='Prayer' />
      <VStack px='6' pt='120' alignItems='start'>
        <Text fontWeight='bold' fontSize='2xl'>Permohonan Doa</Text>
        <PrayerList prayers={prayers} />
      </VStack>
      <VStack w='100%' position='fixed' bottom='0' px='6' py='4'>
        <Button onClick={() => window.location.href = '/new-prayer'} w='100%' bgColor={theme.primary[500]} color='white'>Tambah</Button>
      </VStack>
    </>
  )
}
