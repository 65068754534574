import React from 'react'

// import { bufferToBase64 } from 'src/utils'
import bufferToBase64 from 'src/utils/bufferToBase64'

import { Image } from '@chakra-ui/react'

export default function Base64Image({ media, ...attr }) {
  return (
    <>
      <Image {...attr} src={(media != null && media.file != null ?
          `data:${media.file.contentType};base64,${bufferToBase64(media.file.data.data)}` :
          ''
        )}
      />
    </>
  )
}
