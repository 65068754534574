import React from 'react'

import { StreamItem } from 'src/components'

import { VStack, Spinner } from '@chakra-ui/react'

export default function StreamList({ streams, ...attr }) {
  if (streams == null) return (
    <>
      <VStack {...attr}>
        <Spinner />
      </VStack>
    </>
  )
  return (
    <>
      <VStack {...attr}>
        {
          streams.map((stream, idx) =>
            <StreamItem key={idx} mt='2' stream={stream} />
          )
        }
      </VStack>
    </>
  )
}
