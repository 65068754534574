import React from 'react'

import { HStack, Icon, IconButton, Image, Text, VStack } from '@chakra-ui/react'
import { SiYoutube, SiFacebook, SiInstagram, SiTiktok } from 'react-icons/si'
import { AiFillPhone } from 'react-icons/ai'

export default function Footer({ ...attr }) {
  return (
    <>
      <VStack {...attr} p='6' alignItems='center'>
        <HStack spacing='4'>
          <Image h='39' src={require("src/assets/images/playStore.png")} 
            onClick={() => window.location.href = 'https://play.google.com/store/apps/details?id=com.kalvariku.stable'}
          />
          <Image h='41' src={require("src/assets/images/appStore.png")} 
            onClick={() => window.location.href = 'https://apps.apple.com/id/app/kalvariku/id1666470625'}
          />
        </HStack>
        <HStack w='100%' pt='4' justifyContent='space-between'>
          <Text>GBI Kalvari @ 2022</Text>
          <HStack spacing='2'>
            <IconButton size='sm' variant='outline' borderColor='black' 
              rounded='full' icon={<Icon as={SiYoutube} />} 
              onClick={() => window.location.href='https://www.youtube.com/c/GBIKalvariCirebon'}
            />
            <IconButton size='sm' variant='outline' borderColor='black' 
              rounded='full' icon={<Icon as={SiTiktok} />}
              onClick={() => window.location.href='https://www.tiktok.com/@gbikalvari.cirebon'} 
            />
            <IconButton size='sm' variant='outline' borderColor='black' 
              rounded='full' icon={<Icon as={SiFacebook} />} 
              onClick={() => window.location.href='https://web.facebook.com/GBIKalvariCirebon?mibextid=ZbWKwL&_rdc=1&_rdr'}
            />
            <IconButton size='sm' variant='outline' borderColor='black' 
              rounded='full' icon={<Icon as={AiFillPhone} />} 
              onClick={() => window.location.href='tel:0231206841'}
            />
            <IconButton size='sm' variant='outline' borderColor='black' 
              rounded='full' icon={<Icon as={SiInstagram} />} 
              onClick={() => window.location.href='https://www.instagram.com/gbi_kalvari/'}
            />
          </HStack>
        </HStack>
      </VStack>
    </>
  )
}
